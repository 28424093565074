<template>
  <dash-page-new
      :title="entity.title || $t('News')"
      :subtitle="$t('EditNews')"
      :root="$t('News')"
      icon="mdi-newspaper"
      :no-content-condition="$store.state.auth.userType !== 'superadmin'"
  >

    <template #header_action>
      <ft-select
          v-model="selectedLang"
          :items="langsSelect"
      >

        <h5>{{ selectedLang }} <v-icon>mdi-menu-down</v-icon></h5>

      </ft-select>
    </template>
    <template #default>

      <ws-accordion
          class="mt-6"
          :items="accordeonItems"
      >

        <template #action.is_published>
          <div class="d-flex justify-end">
            <ws-switch
                class="mr-6"
                @input="saveNews"
                v-model="entity.is_published"
            />
          </div>
        </template>

        <template #action.email>
          <div class="d-flex align-center justify-end mr-6">
            <ws-button label="Send Now" class="mr-3" outlined :color="wsACCENT" />
            <ws-switch
                @input="saveNews"
                v-model="entity.include_newsletter"
            />

          </div>
        </template>

        <template #action.icon>
          <ws-text-field
              width="270px"
              class="mr-6"
              @change="saveNews"
              v-model="entity.preview_icon"
          />
        </template>

        <template #action.date_published>
            <ws-date-picker
                class="mr-6"
                @input="saveNews"
                v-model="entity.date_published"
                time-stamp
            />
        </template>

        <template #action.alias>
          <ws-text-field
              width="270px"
              class="mr-6"
              @change="saveNews"
              v-model="entity.alias"
          />
        </template>

        <template #action.external_link>
          <ws-text-field
              width="270px"
              class="mr-6"
              placeholder="https://youtube.com/watch"
              @change="saveNews"
              v-model="entity.external_link"
          />
        </template>

        <template #action.category>
          <ws-select
              :items="newsTypesSelectItems"
              width="270px"
              class="mr-6"
              @change="saveNews"
              v-model="entity.category_value"
          />

        </template>

        <template #item.preview>
          <div class="d-flex justify-center pt-6 pb-16">
            <div  style="max-width : 762px">
              <news-preview-editor
                  class="mt-6"
                  v-model="entity"
                  @input="saveNews(true)"
              />
            </div>
          </div>
        </template>

        <template #item.content>
          <div class="d-flex justify-center pt-6 pb-16">
            <div  class="pb-16" style="max-width : 762px" >

              <news-content-editor
                  v-model="entity"
                  @input="saveNews()"
              />

            </div>
          </div>

        </template>

      </ws-accordion>


    </template>

  </dash-page-new>

</template>

<script>
import {mapActions} from "vuex";
import newsPreviewEditor from "@/components/pages/adminDashboard/news/UI/newsPreviewEditor";
import newsContentEditor from "@/components/pages/adminDashboard/news/UI/newsContentEditor";

export default {
  name: "editNews",
  props : ['uuid'],
  components : {
    newsPreviewEditor,
    newsContentEditor
  },
  data() {
    return {
      entity : {
        content : [],
        preview : [],
      },
      //lang
      selectedLang : 'ua',
      langBase : [],
      newsTypesSelect : [ 'news.articles' ,  'news.updates' , 'news.streams' , 'news.mass_media' , 'news.community'],
      langsSelect : [
          { text : 'UA' , value : "ua" },
          { text : 'EN' , value : "en" },
          { text : 'RU' , value : "ru" }
      ]
    }
  },
  computed : {

    newsTypesSelectItems() {
      let items = []
      this.newsTypesSelect.forEach( el=> {
        items.push({
          text : this.$t(el),
          value : el
        })
      })
      return items
    },

    navigation() {
      return [
        { icon : 'mdi-form-select' , value : 0 },
        { icon : 'mdi-content'   , value : 1 },
        { icon : 'mdi-cogs'  , value : 2 },
      ]
    },

    accordeonItems() {
      let items =  [
        {
          name : this.$t('IsPublished') ,
          subtitle : this.$t('IsPublished') ,
          expanded : false,
          noExpand : true,
          value : 'is_published',
          icon : 'mdi-eye'
        },
        {
          name : this.$t('Email') ,
          subtitle : this.$t('Email') ,
          expanded : false,
          noExpand : true,
          value : 'email',
          icon : 'mdi-eye'
        },
        {
          name : this.$t('DatePublished') ,
          subtitle : this.$t('DatePublished') ,
          expanded : false,
          noExpand : true,
          value : 'date_published',
          icon : 'mdi-calendar'
        },
        {
          name : this.$t('Alias') ,
          subtitle : this.$t('Alias') ,
          expanded : false,
          noExpand : true,
          value : 'alias',
          icon : 'mdi-link'
        },
        {
          name : this.$t('Category') ,
          subtitle : this.$t('CategoryDescription') ,
          expanded : false,
          noExpand : true,
          value : 'category',
          icon : 'mdi-file-tree'
        },
        {
          name : this.$t('ExternalLink') ,
          subtitle : this.$t('ExternalLinkDescription') ,
          expanded : false,
          noExpand : true,
          value : 'external_link',
          icon : 'mdi-link'
        },
        {
          name : this.$t('NewsPreview') ,
          subtitle : this.$t('NewsPreviewDescription') ,
          expanded : true,
          value : 'preview',
          icon : 'mdi-eye'
        },
        {
          name : this.$t('Content') ,
          subtitle : this.$t('NewsContent') ,
          expanded : true,
          value : 'content',
          icon : 'mdi-cash-multiple',

        },
      ]

      if ( this.entity.type === 'notification' ) {
        items.splice(3,0 , {
          name : this.$t('Icon') ,
          subtitle : this.$t('NewsIconDescription') ,
          expanded : false,
          noExpand : true,
          value : 'icon',
          icon : 'mdi-cog'
        })
      }

      return items
    },

  },
  watch : {
    selectedLang() {
      this.initPage()
    }
  },
  methods : {
    ...mapActions('news', [
          'GET_NEWS_MAIN_ONE',
          'ADD_EDIT_NEWS_MAIN',
          'UPLOAD_IMAGE'
        ]
    ),

    saveNews(noContent = false) {

      let data = this.COPY(this.entity)
      data.lang = this.selectedLang
      if ( noContent ) {
        delete data.content
      }

      let result = this.ADD_EDIT_NEWS_MAIN(data)

      if ( !result ) {
        return this.notify(this.$t('NetworkError'))
      }

    },
    uploadImage(file) {
      this.loading = true
      this.LOADING = true
      return this.UPLOAD_IMAGE({file : file, param : 'news'}).then((out)=>{
        if ( out.result ) {
          this.entity.img = out.data.url
          this.entity = JSON.parse(JSON.stringify(this.entity))
          this.saveNews()
        }
        this.$store.state.dashboard.UPLOAD_LOADING = false
        this.loading = true
        this.LOADING = false

        this.uploadLogo = false
      }).catch(()=>{
        this.loading = true
        this.LOADING = false
      })

    },

    initPage() {
      if ( !this.uuid ) { return }

      this.loading = true
      this.GET_NEWS_MAIN_ONE({uuid : this.uuid , lang : this.selectedLang}).then(out=>{
        if(out.result) {
          this.entity = out.data
        }
        this.loading = false
      }).catch(error=>{ console.log(error) })
    }


  },
  async mounted(){
   this.initPage()
  }
}
</script>

<style scoped>

</style>