<template>
  <v-sheet
      width="782"
      color="transparent"
      class="py-8 "
  >
    <h4 style="font-size: 16px; font-weight: 400"
        :style="`color : ${wsDARKLIGHT}`"
        class="mb-2 px-8"
    >
      {{ PARSE_DATE(entity.date_published , false ,true) }}
    </h4>

    <v-textarea
        v-model="entity.title"
        @change="$emit('input' , entity)"
        :placeholder="$t('EnterNewsTitle')"
        class="pa-0 px-5 ma-0 heading text-center hoverUnderline title"
        style="font-weight: 600; font-size: 42px"
        background-color="transparent"
        row-height="10"
        ref="heading"
        hide-details
        single-line
        auto-grow
        dense
        flat
        solo
    />

    <div class="px-8 mt-8">
      <div v-if="entity.img" >
        <v-img  height="466" class="wsRoundedHalf" :src="entity.img"  transition="xxx" />
        <div class="d-flex justify-end">
          <ws-button
              @click="deleteImage"
              label="Delete"
              icon="mdi-delete"
              class="mt-3"
              outlined
          />
        </div>



      </div>

      <ws-file-uploader
          v-else
          @success="uploadImage"
          public
          superadmin
      >

      </ws-file-uploader>
    </div>

    <v-textarea
        v-model="entity.subtitle"
        @change="$emit('input' , entity)"
        :placeholder="$t('EnterNewsSubtitle')"
        class="pa-0 px-5 ma-0 my-12 heading text-center hoverUnderline subtitle"
        background-color="transparent"
        row-height="10"
        style="font-weight: 300; font-size: 32px"
        ref="subtitle"
        hide-details
        single-line
        auto-grow
        dense
        flat
        solo
    />


    <wsTextEditor
        v-model="entity.content"
        @input="$emit('input' , entity)"
        :placeholder="$t('EnterNewsContent')"
        is-public
        superadmin
    />

  </v-sheet>
</template>

<script>
export default {
  name: "newsPreviewEditor",
  props : {
    value : {
      type : Object,
      default() { return {} }
    },
  },
  data() {
    return {
      entity : {}
    }
  },
  watch : {
    value : {
      handler() {
        if ( !this.EQ(this.value , this.entity ) ) {
          this.entity = this.COPY(this.value)
        }
      },
      deep : true
    }
  },
  methods : {
    deleteImage() {
      this.entity.img = null
      this.entity = this.COPY(this.entity)
      this.$emit('input' , this.entity)
    },
    uploadImage(result) {
      this.entity.img = result.url
      this.entity = this.COPY(this.entity)
      this.$emit('input' , this.entity)
    }
  },
  mounted() {
    this.entity = this.COPY(this.value)

    let el = this.$refs.heading.$el
    let headerStyle = el.querySelector('textarea');
    headerStyle.style.color = this.wsDARKER
    headerStyle.style.fontSize = "42px"
    headerStyle.style.lineHeight = '50.4px'

    let subtitle = this.$refs.subtitle.$el
    let subtitleStyle = subtitle.querySelector('textarea');
    subtitleStyle.style.color = this.wsACCENT
    subtitleStyle.style.lineHeight = '48px'

  }
}
</script>

<style scoped>
textarea {
  line-height: 5 !important;
}
</style>