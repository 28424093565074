<template>
  <v-sheet
      width="782"
      max-width="782"
      :color="!isNotification ? backgroundColor : 'transparent'"
      class="wsRoundedHalf"
      :dark="!['light' , 'medium_light'].includes(entity.background_color) && !isNotification"
      :class="[{'py-8' : !isNotification}]"
  >

    <v-row no-gutters  style="flex-wrap: nowrap; height: 100% !important" class="fill-height"  >

      <v-col v-if="isNotification" class="flex-grow-0 flex-shrink-0 ">
        <v-sheet
            :color="backgroundColor"
            min-width="171" width="171" height="116"
            class="d-flex align-center justify-center wsRoundedHalf"
        >
          <v-icon x-large :color="wsACCENT">{{ entity.preview_icon || 'mdi-cog' }}</v-icon>
        </v-sheet>
      </v-col>

      <v-col  style="min-width: 100px; max-width: 100%;" class="flex-grow-1 flex-shrink-0">
        <v-sheet color="transparent" class="v-text-field--full-width"  width="100%" >
          <div class="d-flex justify-space-between align-center pl-8" :class="[{'pr-8' : !isNotification}]">

            <h4 style="font-size: 16px; font-weight: 400"
                :style="`color : ${!(entity.background_color ==='medium' || entity.background_color ==='accent') ? wsDARKLIGHT : 'white'}`"
                class="mb-2 "
            >
              {{ PARSE_DATE(entity.date_published , false ,true) }}
            </h4>

            <div class="d-flex align-center">
              <ft-select
                  v-model="entity.background_color"
                  @input="$emit('input' , entity)"
                  :items="colorsSelect"
              >
                <v-btn  icon>
                  <v-icon>mdi-palette</v-icon>
                </v-btn>
              </ft-select>
              <ft-select
                  v-model="entity.type"
                  @input="$emit('input' , entity)"
                  :items="typesSelect">
                <v-btn icon>
                  <v-icon>mdi-cog</v-icon>
                </v-btn>
              </ft-select>
            </div>

          </div>
          <v-textarea
              v-model="entity.title"
              @change="$emit('input' , entity)"
              :style="titleStyle"
              :placeholder="$t('EnterName')"
              class="pa-0 px-5 ma-0 heading text-center hoverUnderline"
              background-color="transparent"
              row-height="1"
              ref="heading"
              hide-details
              single-line
              auto-grow
              dense
              flat
              solo
          />
          <wsTextEditor
              v-model="entity.preview"
              @input="$emit('input' , entity)"
              is-public
              superadmin
          />

          <h4
              class="pl-8 mt-3 pointer"
              :style="`color: ${wsATTENTION}`" >{{ $t('MoreDetails') }}
            <v-icon :color="wsATTENTION">mdi-arrow-right</v-icon>
          </h4>

        </v-sheet>
      </v-col>

    </v-row>

  </v-sheet>
</template>

<script>
export default {
  name: "newsPreviewEditor",
  props : {
    value : {
      type : Object,
      default() { return {} }
    },
  },
  data() {
    return {
      entity : {}
    }
  },
  computed : {
    isNotification() {
      return this.entity.type === 'notification'
    },
    backgroundColor() {

      switch (this.entity.background_color) {
        case 'light' : return this.wsLIGHTCARD
        case "medium_light" : return this.wsLIGHTACCENT
        case "medium" : return this.wsDARKLIGHT
        case "dark" : return this.wsACCENT
        case "accent"  : return this.wsATTENTION
        default : return this.wsLIGHTCARD
      }
    },
    colorsSelect() {
      return [
        { text : this.$t('Light')  , value : "light"  },
        { text : this.$t('MediumLight') , value : "medium_light" },
        { text : this.$t('Medium') , value : "medium" },
        { text : this.$t('Dark')   , value : "dark"   },
        { text : this.$t('Accent') , value : "accent" },
      ]
    },
    typesSelect() {
      return [
        { text : this.$t('Default') , value : "default" },
        { text : this.$t('Notification') , value : "notification" }
      ]
    },

    titleStyle() {
      let style = ""
      style += "font-size : 24px;"
      style += "font-weight : 600;"
      return style
    }
  },
  watch : {
    value : {
      handler() {
        if ( !this.EQ(this.value , this.entity ) ) {
          this.entity = this.COPY(this.value)
        }
      },
      deep : true
    }
  },
  mounted() {
    this.entity = this.COPY(this.value)
  }
}
</script>

<style scoped>

</style>